import { useClientStore } from '../store/client'
import { useCountryStore } from '../store/country'

const client = useClientStore()
/* eslint-disable no-prototype-builtins */
export default (root: any, permissions: any, be: string, country: string, features: any, clientFinNavigationData: MigrationNavigationData) => {
  const countryStore = useCountryStore()

  const clientDropDown = [
    {
      id: 2,
      type: 'internal',
      link: '/dashboard/client/jobs/new-job-template',
      target: '_self',
      linktext: root.t('header.clients.addJob'),
      icon: 'add',
      class: 'user-link',
      permission: permissions && permissions?.hasOwnProperty('jobs.store'),
    },
    {
      id: 3,
      type: 'title',
      content: root.t('header.clients.help'),
      variant: 'caption',
      class: 'grey600 caption',
    },
    {
      id: 4,
      type: 'internal',
      link: '/platform-guide',
      target: '_self',
      linktext: root.t('header.clients.guide'),
      icon: 'bookManual',
      class: 'loggedin',
      permission: true,
    },
    {
      id: 5,
      type: 'external',
      link: root.t('helpSection.link'),
      target: '_blank',
      linktext: root.t('header.clients.help'),
      icon: 'bubbleQuestion',
      class: 'loggedin',
      permission: true,
    },
    {
      id: 6,
      type: 'external',
      link: be + '/feedback/product-feedback',
      target: '_self',
      linktext: root.t('header.clients.feedback'),
      icon: 'speechBubble',
      class: 'loggedin',
      permission: true,
    },
    {
      id: 7,
      type: 'title',
      content: root.t('header.clients.other'),
      variant: 'caption',
      class: 'grey600 caption',
    },
    {
      id: 8,
      type: 'external',
      link: be + '/dashboard/profile',
      target: '_self',
      linktext: root.t('header.clients.profile'),
      icon: 'profile',
      class: 'loggedin',
      permission: true,
    },
    {
      id: 9,
      type: 'external',
      link: be + '/dashboard/clients',
      target: '_self',
      linktext: root.t('header.clients.locations'),
      icon: 'company',
      class: 'loggedin',
      permission: true,
    },
    {
      id: 10,
      type: 'external',
      link: root.t('header.clients.referral.link'),
      target: '_blank',
      linktext: root.t('header.clients.referral.linkText'),
      icon: 'gift',
      class: 'loggedin',
    },
    {
      id: 11,
      target: '_self',
      linktext: root.t('header.clients.financial'),
      icon: 'hourlyRateEuro',
      class: 'loggedin',
      permission: permissions && permissions.hasOwnProperty('invoice_groups.index') && features && !features.external_invoicing,
      ...clientFinNavigationData,
    },
    {
      id: 12,
      type: 'internal',
      link: '/dashboard/client/invoices',
      target: '_self',
      linktext: root.t('header.clients.financial'),
      icon: 'hourlyRateEuro',
      class: 'loggedin',
      permission: permissions && permissions.hasOwnProperty('invoice_groups.index') && features && features.external_invoicing,
    },
    {
      id: 13,
      type: 'external',
      link: be + '/dashboard/client/statistics',
      target: '_self',
      linktext: root.t('header.clients.statistics'),
      icon: 'graph',
      class: 'loggedin',
      permission: country === 'nl' && permissions && permissions.hasOwnProperty('client_statistics.show'),
    },
    {
      id: 14,
      type: 'external',
      link: be + '/dashboard/client/blacklisted-freelancers',
      target: '_self',
      linktext: root.t('header.clients.blocked'),
      icon: 'block',
      class: 'loggedin',
      permission: permissions && permissions.hasOwnProperty('blacklisted_contractors.index'),
    },
    {
      id: 15,
      type: 'internal',
      link: '/aanleveren-belastingdienst',
      target: '_self',
      linktext: root.t(`header.clients.ib47.${countryStore.country}`),
      icon: 'financeReceipt',
      class: 'loggedin',
      permission: country === 'nl' && permissions && permissions.hasOwnProperty('ubd.page_access'),
    },
    {
      id: 16,
      type: 'external',
      link: be + '/dashboard/client/shift-export',
      target: '_self',
      linktext: root.t('header.clients.export'),
      icon: 'download',
      class: 'loggedin',
      permission: permissions && permissions.hasOwnProperty('matches.export'),
    },
    {
      id: 17,
      type: 'internal',
      link: '/dashboard/client/notification-settings',
      target: '_self',
      linktext: root.t('header.clients.notifications'),
      icon: 'notificationOn',
      class: 'loggedin',
      permission: true,
    },
  ]
  return {
    clientDropDown,
  }
}
