<script setup lang="ts">
import { Toaster, StatusNotification } from '@temperworks/components'
import ClientNavbar from '../components/layout/ClientNavbar.vue'
import FreeflexerNavbar from '../components/layout/FreeflexerNavbar.vue'
import CountrySelector from '../components/layout/CountrySelector.vue'
import { useUserStore } from '../store/user'
import { useErrorHandlerStore } from '../store/errorHandler'
import { useStatusNotificationStore } from '../store/statusNotificationHandler'

const userStore = useUserStore()
const errorStore = useErrorHandlerStore()
const notificationStore = useStatusNotificationStore()

</script>

<template>
  <component :is="userStore.isWorker ? FreeflexerNavbar : ClientNavbar" />
  <slot />
  <CountrySelector />
  <Toaster
    v-if="errorStore.errorDisplay"
    :type="errorStore.errorType"
    :title="errorStore.errorTitle"
    :toaster-text="errorStore.errorMessage"
    :open="errorStore.errorDisplay"
    @close-toaster="errorStore.closeError(true)"
  />

  <TransitionGroup
    name="notifications"
    tag="div"
    class="notifications"
  >
    <StatusNotification
      v-for="notification in notificationStore.notifications"
      :id="notification.id"
      :key="notificationStore.notifications.length + notification.id + notification.open"
      :open="notification.open"
      :type="notification.type"
      :text="notification.text"
      :button-text="notification?.buttonText"
      :with-close-button="notification?.withCloseButton"
      @action="notification?.action"
      @close="notificationStore.closeNotification(notification.id)"
    />
  </TransitionGroup>


</template>
<style lang="scss" scoped>
.notifications {
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 2147483002;
  width: fit-content;
  display: flex;
  flex-direction: column-reverse;
}

.notifications-enter-active,
.notifications-leave-active {
  transition: all 0.5s ease;
}
.notifications-enter-from,
.notifications-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
