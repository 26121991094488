/* eslint-disable no-prototype-builtins */
export default (root:any, permissions:any, be:string, country:string, badge:any, features:any) => {
  const clientMenuMobile = [
    {
      id: 1,
      permission: true,
      link: '/get-started/',
      icon: 'calendar',
      class: 'loggedin',
      linktext: root.t('header.clients.onboardingDashboard'),
    },
    {
      id: 2,
      permission: false,
      link: '/dashboard/',
      icon: 'calendar',
      class: 'loggedin',
      linktext: root.t('header.clients.dashboard'),
    },
    {
      id: 3,
      link: be + '/dashboard/planning',
      icon: 'calendar',
      class: 'loggedin',
      linktext: root.t('header.clients.planning'),
      permission: true
    },
    {
      id: 4,
      link: be + '/client/projects',
      icon: 'Calendar',
      linktext: root.t('header.clients.projects'),
      permission: permissions && permissions.hasOwnProperty('projects.index'),
    },
    {
      id: 5,
      link: be + '/dashboard/checkouts',
      icon: 'Calendar',
      badge,
      linktext: root.t('header.clients.checkout'),
      permission: permissions && permissions.hasOwnProperty('matches.checkouts.manage'),
    },
    {
      id: 6,
      type: 'external',
      link: be + '/dashboard/client/flexpools',
      target: '_self',
      icon: 'HeartMedium',
      class: 'loggedin',
      linktext: root.t('header.clients.flexpools'),
      permission: permissions && permissions.hasOwnProperty('flexpools.index'),
    },
    {
      id: 8,
      type: 'internal',
      link: '/dashboard/client/jobs/new-job-template',
      target: '_self',
      linktext: root.t('header.clients.addJob'),
      icon: 'AddMedium',
      class: 'loggedin',
      permission: permissions && permissions.hasOwnProperty('jobs.store')
    },
    {
      id: 9,
      type: 'title',
      content: root.t('header.clients.help'),
      variant: 'caption',
      class: 'grey600 caption',
    },
    {
      id: 10,
      type: 'internal',
      link: '/platform-guide',
      target: '_self',
      linktext: root.t('header.clients.guide'),
      icon: 'ManualMedium',
      class: 'loggedin',
      permission: true
    },
    {
      id: 11,
      type: 'external',
      link: root.t('helpSection.link'),
      target: '_blank',
      linktext: root.t('header.clients.help'),
      icon: 'HelpMedium',
      class: 'loggedin',
      permission: true
    },
    {
      id: 12,
      type: 'external',
      link: be + '/feedback/product-feedback',
      target: '_self',
      linktext: root.t('header.clients.feedback'),
      icon: 'SpeakMedium',
      class: 'loggedin',
      permission: true
    },
    {
      id: 13,
      type: 'title',
      content: root.t('header.clients.other'),
      variant: 'caption',
      class: 'grey600 caption',
    },
    {
      id: 14,
      type: 'external',
      link: be + '/dashboard/profile',
      target: '_self',
      linktext: root.t('header.clients.profile'),
      icon: 'ProfileMedium',
      class: 'loggedin',
      permission: true
    },
    {
      id: 15,
      type: 'external',
      link: be + '/dashboard/clients',
      target: '_self',
      linktext: root.t('header.clients.locations'),
      icon: 'CompanyMedium',
      class: 'loggedin',
      permission: true
    },
    {
      id: 16,
      type: 'external',
      link: root.t('header.clients.referral.link'),
      target: '_blank',
      linktext: root.t('header.clients.referral.linkText'),
      icon: 'GiftMedium',
      class: 'loggedin'
    },
    {
      id: 17,
      type: 'external',
      link: be + '/dashboard/client/invoices',
      target: '_self',
      linktext: root.t('header.clients.financial'),
      icon: 'EuroMedium',
      class: 'loggedin',
      permission:
        permissions &&
        permissions.hasOwnProperty('invoice_groups.index') &&
        features &&
        !features.external_invoicing
    },
    {
      id: 18,
      type: 'external',
      link: be + '/dashboard/client/statistics',
      target: '_self',
      linktext: root.t('header.clients.statistics'),
      icon: 'GraphMedium',
      class: 'loggedin',
      permission: permissions && permissions.hasOwnProperty('client_statistics.show')
    },
    {
      id: 19,
      type: 'external',
      link: be + '/dashboard/client/blacklisted-freelancers',
      target: '_self',
      linktext: root.t('header.clients.blocked'),
      icon: 'BlockUserMedium',
      class: 'loggedin',
      permission: permissions && permissions.hasOwnProperty('blacklisted_contractors.index')
    },
    {
      id: 20,
      type: 'external',
      link: be + '/dashboard/client/ib47',
      target: '_self',
      linktext: root.t('header.clients.ib47'),
      icon: 'DocumentMedium',
      class: 'loggedin',
      permission: country === 'nl' && (permissions && permissions.hasOwnProperty('vog.show'))
    },
    {
      id: 21,
      type: 'external',
      link: be + '/dashboard/client/shift-export',
      target: '_self',
      linktext: root.t('header.clients.export'),
      icon: 'ExportMedium',
      class: 'loggedin',
      permission: permissions && permissions.hasOwnProperty('matches.export')
    },
    {
      id: 22,
      link: be + '/dashboard/client/notification-settings',
      linktext: root.t('header.clients.notifications'),
      icon: 'NotificationsMedium',
      class: 'loggedin',
      permission: true
    },
  ]
  return {
    clientMenuMobile,
  }
}
