/* eslint-disable no-prototype-builtins */
import { useClientStore } from '../store/client'

export default (route:any, root:any, permissions:any, be:string) => {
  const client = useClientStore()
  const quickActionsClient = [
    {
      id: 1,
      type: 'external',
      link: be + '/dashboard/clients/lookup',
      linktext: root.t('header.clients.companyAdd'),
      icon: 'addCompany',
      class: 'loggedin user-link',
      permission: route === 'locations'
    },
    {
      id: 2,
      type: 'title',
      content: root.t('header.clients.quick'),
      variant: 'caption',
      class: 'grey600 caption',
    },
    {
      id: 3,
      link: `/dashboard/${ window.document.cookie.includes('beta_dashboard_planning_page') ? 'planning/create-shift' : 'client/jobs/shifts/create'}?on=${new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: 'numeric', day: 'numeric' })}`,
      linktext: root.t('header.clients.createShift'),
      icon: 'add',
      class: 'loggedin user-link',
      permission: permissions && permissions.hasOwnProperty('shifts.store') && !!client.firstLocationShift
    },
    {
      id: 4,
      link: '/dashboard/client/jobs/new-job-template',
      linktext: root.t('header.clients.addJob'),
      icon: 'add',
      class: 'loggedin user-link',
      permission: permissions && permissions.hasOwnProperty('jobs.store')
    },
  ]
  return {
    quickActionsClient
  }
}
